var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('el-carousel',{attrs:{"trigger":"click","interval":5000,"arrow":"never","height":"40vw"}},_vm._l((_vm.bannerList),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"banner-container",staticStyle:{"width":"100%","height":"100%"},style:({
          background: 'url(' + item.src + ')',
          backgroundSize: '100% 100%',
        })},[_c('div',{staticClass:"banner-text",domProps:{"innerHTML":_vm._s(item.text)}}),_c('div',{staticClass:"banner-text banner-button",on:{"click":_vm.initApplication}},[_vm._v(" 开始报名 ")])])])}),1),_c('div',{staticClass:"flex",staticStyle:{"justify-content":"center","flex-direction":"column","padding":"0 5vw"}},[_c('h2',{staticClass:"content-title"},[_vm._v("大赛相关")]),_c('div',{staticClass:"news-container",staticStyle:{"margin":"0 70px"}},[_c('el-row',{attrs:{"gutter":30}},[_c('el-col',{staticStyle:{"margin-bottom":"20px"}},[_vm._v(" 为进一步促进机械行业新质生产力发展，激发机械行业创新活力，发掘新亮点、启发新智慧、提高新效能，充分挖掘 和推广企业管理经营实践中的原创性创新亮点，构建机械行业新质生产力创新改善管理生态圈，现举办首届全国机械工业创 新改善大赛，获奖成果将入选机械行业创新改善案例库，推荐申报机械工业创新改善管理成果奖项。 ")])],1),_c('el-row',{attrs:{"gutter":30}},_vm._l((_vm.routerList),function(item,index){return _c('el-col',{key:'router' + index,staticStyle:{"padding":"15px"},attrs:{"span":item.span * 8}},[_c('div',{staticClass:"route-list",on:{"click":function($event){return _vm.routerJump(item)}}},[_c('div',{staticClass:"route-background",style:({
                background: 'url(' + item.src + ')',
                backgroundSize: '100% 100%',
              })}),_c('div',{staticClass:"route-content"},[_c('div',{staticClass:"route-title"},[_vm._v(_vm._s(item.title))]),_vm._l((item.content),function(content,index){return _c('div',{key:'content' + index},[_c('div',{staticClass:"route-text"},[_vm._v(_vm._s(content.text))]),_c('div',{staticClass:"route-desc"},[_vm._v(_vm._s(content.desc))])])}),_c('div',{staticClass:"route-more"},[_vm._v("了解更多")])],2)])])}),1)],1)]),_c('div',{staticClass:"flex",staticStyle:{"justify-content":"center","flex-direction":"column","padding":"0 5vw"}},[_c('h2',{staticClass:"content-title"},[_vm._v("友情链接")]),_c('div',{staticClass:"news-container",staticStyle:{"margin":"0 70px"}},[_c('el-row',{attrs:{"gutter":80}},_vm._l((_vm.linkList),function(item,index){return _c('el-col',{key:'link' + index,staticStyle:{"margin":"15px 0"},attrs:{"span":8}},[_c('el-card',{class:item.noBorder ? 'no-border' : ''},[_c('img',{staticClass:"link-logo",style:({ width: item.allWidth ? '100%' : '' }),attrs:{"src":item.src},on:{"click":function($event){return _vm.openLink(item.linkTo)}}})])],1)}),1)],1)]),_c('div',{staticClass:"flex",staticStyle:{"justify-content":"center","flex-direction":"column","padding":"0 5vw"}},[_c('h2',{staticClass:"content-title"},[_vm._v("技术支持")]),_c('div',{staticClass:"news-container",staticStyle:{"margin":"0 70px"}},[_c('el-row',{attrs:{"gutter":80}},_vm._l((_vm.supportList),function(item,index){return _c('el-col',{key:'link' + index,staticStyle:{"margin":"15px 0"},attrs:{"span":8}},[_c('el-card',[_c('img',{staticClass:"link-logo",attrs:{"src":item.src},on:{"click":function($event){return _vm.openLink(item.linkTo)}}})])],1)}),1)],1)]),_c('application-dialog',{ref:"applicationDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }