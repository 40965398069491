<!--
 * @Author: chenghaoyuan 517048787@qq.com
 * @Date: 2023-09-11 17:09:21
 * @LastEditors: chenghaoyuan 517048787@qq.com
 * @LastEditTime: 2023-09-14 22:27:16
 * @FilePath: \digital-earth\src\views\HomeView.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <el-carousel trigger="click" :interval="5000" arrow="never" height="40vw">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <div
          :style="{
            background: 'url(' + item.src + ')',
            backgroundSize: '100% 100%',
          }"
          style="width: 100%; height: 100%"
          class="banner-container"
        >
          <div v-html="item.text" class="banner-text"></div>
          <div class="banner-text banner-button" @click="initApplication">
            开始报名
          </div>
          <!-- <div v-html="item.subtitle" class="banner-subtitle"></div> -->
        </div>
      </el-carousel-item>
    </el-carousel>
    <div
      class="flex"
      style="justify-content: center; flex-direction: column; padding: 0 5vw"
    >
      <h2 class="content-title">大赛相关</h2>
      <div class="news-container" style="margin: 0 70px">
        <el-row :gutter="30">
          <el-col style="margin-bottom: 20px">
            为进一步促进机械行业新质生产力发展，激发机械行业创新活力，发掘新亮点、启发新智慧、提高新效能，充分挖掘
            和推广企业管理经营实践中的原创性创新亮点，构建机械行业新质生产力创新改善管理生态圈，现举办首届全国机械工业创
            新改善大赛，获奖成果将入选机械行业创新改善案例库，推荐申报机械工业创新改善管理成果奖项。
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col
            v-for="(item, index) in routerList"
            :key="'router' + index"
            :span="item.span * 8"
            style="padding: 15px"
          >
            <div class="route-list" @click="routerJump(item)">
              <div
                class="route-background"
                :style="{
                  background: 'url(' + item.src + ')',
                  backgroundSize: '100% 100%',
                }"
              ></div>

              <div class="route-content">
                <div class="route-title">{{ item.title }}</div>
                <div
                  v-for="(content, index) in item.content"
                  :key="'content' + index"
                >
                  <div class="route-text">{{ content.text }}</div>
                  <div class="route-desc">{{ content.desc }}</div>
                </div>
                <div class="route-more">了解更多</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- <div class="flex" style="justify-content: center; flex-direction: column">
      <h2 class="content-title">新闻公告</h2>
      <div class="news-container" style="margin: 0 70px">
        <el-row :gutter="30">
          <el-col
            v-for="(item, index) in newsList"
            :key="'news' + index"
            :span="8"
          >
            <div class="news-bkg" @click="gotoNewsDetail">
              <div class="img-container">
                <div class="news-tag">新闻动态</div>
                <div
                  class="news-img"
                  :style="{
                    background: 'url(' + item.src + ')',
                    backgroundSize: '100% 100%',
                  }"
                ></div>
              </div>
              <div class="text-content">
                <h4 class="news-title">{{ item.title }}</h4>
                <div class="news-time">{{ item.time }}</div>
                <div class="news-content">{{ item.content }}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="news-bkg" style="padding: 25px 0">
              <div class="news-tag">新闻动态</div>
              <div style="overflow-y: scroll; height: 430px; padding: 0 30px">
                <div
                  v-for="(item, index) in textNewsList"
                  :key="'textNew' + index"
                  class="text-news-list"
                  @click="gotoNewsDetail"
                >
                  <h4 class="text-title">{{ item.title }}</h4>
                  <div class="text-time">{{ item.time }}</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div> -->
    <div
      class="flex"
      style="justify-content: center; flex-direction: column; padding: 0 5vw"
    >
      <h2 class="content-title">友情链接</h2>
      <div class="news-container" style="margin: 0 70px">
        <el-row :gutter="80">
          <el-col
            :span="8"
            v-for="(item, index) in linkList"
            :key="'link' + index"
            style="margin: 15px 0"
          >
            <el-card :class="item.noBorder ? 'no-border' : ''">
              <img
                :src="item.src"
                class="link-logo"
                @click="openLink(item.linkTo)"
                :style="{ width: item.allWidth ? '100%' : '' }"
              />
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>

    <div
      class="flex"
      style="justify-content: center; flex-direction: column; padding: 0 5vw"
    >
      <h2 class="content-title">技术支持</h2>
      <div class="news-container" style="margin: 0 70px">
        <el-row :gutter="80">
          <el-col
            :span="8"
            v-for="(item, index) in supportList"
            :key="'link' + index"
            style="margin: 15px 0"
          >
            <el-card>
              <img
                :src="item.src"
                class="link-logo"
                @click="openLink(item.linkTo)"
              />
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <application-dialog ref="applicationDialog"></application-dialog>
  </div>
</template>

<script>
// import ApplicationDialog from "./appicationDialog.vue";

export default {
  name: "HomeView",
  // components: {
  //   ApplicationDialog,
  // },
  data() {
    return {
      isDialogVisible: false,
      bannerList: [
        {
          src: require("../assets/images/shoufeng1.jpg"),
          text: `“机械创新  , 质引未来”<br />首届全国机械行业工业工程创新大赛`,
          subtitle:
            "探索数字地球，激发无限可能！<br />——“数字地球”比赛邀你一同揭开地球的神秘面纱。",
        },
      ],
      routerList: [
        // {
        //   src: require("../assets/images/shoufeng2.png"),
        //   span: 2,
        //   title: "大赛简介",
        //   content: [
        //     {
        //       text: "“全国机械工业创新改善大赛",
        //       desc: "机械创新，质引未来",
        //     },
        //   ],
        //   router: "about",
        //   params: { activeName: "first" },
        // },
        // {
        //   src: require("../assets/images/shoufeng3.jpg"),
        //   span: 1,
        //   title: "组织机构",
        //   content: [
        //     {
        //       text: "主办单位",
        //       desc: "中国机械工业企业管理协会",
        //     },
        //     {
        //       text: "特别支持",
        //       desc: "临工集团",
        //     },
        //     {
        //       text: "协办单位",
        //       desc: "天津大学管理与经济学部、创新方法研究会管理技术分会、天津精益管理创新学会、数字化企业网-EWORKS",
        //     },
        //     {
        //       text: "承办单位",
        //       desc: "中机企协现代化管理方法应用专委会、天津昱坤科技有限公司",
        //     },
        //   ],
        //   router: "about",
        //   params: { activeName: "fourth" },
        // },
        {
          src: require("../assets/images/shoufeng2.png"),
          span: 2,
          title: "我要报名",
          content: [
            {
              text: "大赛案例作品提交",
              desc: "报名进行中！",
            },
          ],
          router: "login",
          // openDialog: "apploginDialog",
        },
        {
          src: require("../assets/images/shoufeng3.jpg"),
          span: 1,
          title: "赛事详情",
          content: [
            {
              text: "机械创新，质引未来",
              desc: "双赛道设置，聚焦企业管理和业务流程中的新质生力实践。",
            },
          ],
          router: "contest",
          params: { activeName: "first" },
        },
        {
          src: require("../assets/images/shoufeng4.jpg"),
          span: 3,
          title: "大赛流程",
          content: [
            {
              text: "公开透明选拔、层层严谨评审、专业点评指导",
              desc: "了解详细流程",
            },
          ],
          router: "process",
          params: { activeName: "first" },
        },
        // {
        //   src: require("../assets/images/route06.png"),
        //   span: 1,
        //   title: "大赛成绩",
        //   content: [
        //     {
        //       text: "大赛获奖名单公示",
        //       desc: "获奖名单",
        //     },
        //   ],
        //   router: "achievement",
        //   params: {
        //     activeName: "first",
        //   },
        // },
        // {
        //   src: require("../assets/images/route07.jpg"),
        //   span: 1,
        //   title: "学习平台",
        //   content: [
        //     {
        //       text: "参赛团队共享学习平台",
        //       desc: "开始学习",
        //     },
        //   ],
        //   url: "http://119.3.236.157:8892/#/",
        // },
      ],
      newsList: [
        {
          src: require("../assets/images/news_001.jpg"),
          title: "第十四届“数字地球”全国青少年空天科...",
          time: "2023年09月13日",
          content:
            "9月10日，第十四届“数字地球”全国青少年科技大赛（简称“数字地球”大赛）海南赛区开幕式及选拔赛在海南空天信息研究...",
        },
        {
          src: require("../assets/images/news_002.jpg"),
          title: "北斗之星 熠熠闪光 第十四届“数字地球...",
          time: "2023年09月13日",
          content:
            "2023年9月9日，第十四届“数字地球”全国青少年科技大赛江苏赛区在常州信息职业技术学院顺利开幕。",
        },
      ],
      textNewsList: [
        {
          title:
            "第十四届“数字地球”全国青少年科技大赛河北（含天津）赛区选拔赛成功举办",
          time: "2023年09月13日",
        },
        {
          title: "第十四届“数字地球”全国青少年科技大赛川藏赛区奖项名单公示",
          time: "2023年09月13日",
        },
        {
          title: "第十四届“数字地球”全国青少年科技大赛上海赛区奖项名单公示",
          time: "2023年09月13日",
        },
        {
          title:
            "第十四届“数字地球”全国青少年科技大赛宁夏（含青海省、甘肃省）赛区奖项名单公示",
          time: "2023年09月13日",
        },
        {
          title: "第十四届“数字地球”全国青少年科技大赛浙江赛区奖项名单公示",
          time: "2023年09月13日",
        },
        {
          title: "第十四届“数字地球”全国青少年科技大赛内蒙古赛区奖项名单公示",
          time: "2023年09月13日",
        },
        {
          title: "第十四届“数字地球”全国青少年科技大赛广东赛区奖项名单公示",
          time: "2023年09月13日",
        },
        {
          title: "第十四届“数字地球”全国青少年科技大赛北京赛区奖项名单公示",
          time: "2023年09月13日",
        },
      ],
      linkList: [
        {
          src: require("../assets/images/dasai/jixie.png"),
          linkTo: "http://www.jxcmap.cn/",
          noBorder: true,
        },
        {
          src: require("../assets/images/dasai/lingong.webp"),
          linkTo: "http://www.sdlg.cn",
          noBorder: true,
        },
        {
          src: require("../assets/images/dasai/tianda.png"),
          linkTo: "http://tju.edu.cn",
          noBorder: true,
        },
        {
          src: require("../assets/images/dasai/chuangxin.png"),
          linkTo: "http://www.chinaims.org",
          noBorder: true,
        },
        {
          src: require("../assets/images/dasai/tianjin.png"),
          linkTo: "https://www.tjlean.org",
          allWidth: true,
        },
        {
          src: require("../assets/images/dasai/yikun.png"),
          linkTo: "https://www.yukunkeji.cn",
          allWidth: true,
        },
        // {
        //   src: require("../assets/images/csnc_logo.png"),
        //   linkTo: "http://www.beidouunion.com/",
        // },
      ],
      supportList: [
        {
          src: require("../assets/images/dasai/zhongche.jpg"),
          // linkTo: "http://www.caaet.cn/",
        },
        {
          src: require("../assets/images/dasai/yiqi.png"),
          // linkTo: "http://www.radi.cas.cn/index_65411.html",
        },
        {
          src: require("../assets/images/dasai/jili.jpg"),
          // linkTo: "http://www.jigangdt.com/",
        },
        {
          src: require("../assets/images/dasai/weichailogo.png"),
          // linkTo: "http://www.jigangdt.com/",
        },
        // neimenggu.jpg
        {
          src: require("../assets/images/dasai/shangu.png"),
          // linkTo: "http://www.jigangdt.com/",
        },
        {
          src: require("../assets/images/dasai/dongfang.png"),
          // linkTo: "http://www.jigangdt.com/",
        },
        {
          src: require("../assets/images/dasai/shanghai.png"),
          // linkTo: "http://www.jigangdt.com/",
        },
        {
          src: require("../assets/images/dasai/anhui.png"),
          // linkTo: "http://www.jigangdt.com/",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    document.title = this.$route.meta.title;
  },
  methods: {
    openLink(link) {
      window.open(link);
    },
    routerJump(router) {
      if (router.router) {
        this.$router.push({ name: router.router, params: router.params });
      }
      if (router.openDialog) {
        this.$refs[router.openDialog].init();
      }
      if (router.url) {
        window.open(router.url);
      }
    },
    gotoNewsDetail() {
      this.$router.push({ name: "newsDetail" });
    },
    async initApplication() {
      let that = this;
      // await this.$nextTick(); // 确保 DOM 已更新
      setTimeout(() => {
        that.$refs.applicationDialog.init();
      }, 15);
    },
  },
};
</script>
<style scoped lang="scss">
.banner-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 5vw 0;
  .banner-text {
    text-align: center;
    font-size: 60px;
    color: #ffffff;
    font-weight: bold;
  }
  .banner-subtitle {
    text-align: center;
    font-size: 28px;
    color: #ffffff;
    font-weight: bold;
  }
  .banner-button {
    border-radius: 10px;
    border: 5px solid #ffffff;
    padding: 10px;
    cursor: pointer;
  }
}

.content-title:after {
  content: "";
  width: 30%;
  height: 2px;
  background-color: #409eff;
  position: absolute;
  left: 35%;
  bottom: 0;
  min-width: 35px;
}

.route-list {
  height: 480px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  .route-background {
    height: 480px;
    transition: all 0.5s;
  }
  .route-content {
    padding: 25px 30px;
    bottom: -50px;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    color: #000;
    text-align: left;
    transition: all 0.5s;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .route-title {
      font-size: 0.91em;
      line-height: 1.8em;
      color: #000;
      font-weight: 700;
    }
    .route-text {
      font-size: 1.2em;
      line-height: 1.4em;
      font-weight: 700;
      word-break: bread-word;
      overflow: hidden;
      overflow-wrap: break-word;
    }
    .route-desc {
      margin-bottom: 12px;
      font-size: 0.875em;
      line-height: 1.8em;
      opacity: 0.7;
    }
    .route-more {
      margin-top: 0;
      transition: all 0.5s;
      font-size: 0.875em;
      line-height: 1.875em;
    }
  }
}
.route-list:hover .route-content {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.3) 70%);
  bottom: 0;
}

.route-list:hover .route-background {
  transform: scale(1.05);
  transform-origin: center;
}
.news-bkg {
  background-color: #f2f2f2;
  height: 480px;
  cursor: pointer;
  position: relative;
  .news-img {
    height: 250px;
    transform-origin: center;
    transition: all 0.5s;
  }
  .news-tag {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #096dd9;
    width: 92px;
    height: 32px;
    color: #fff;
    line-height: 32px;
    font-size: 13px;
    z-index: 22;
    font-weight: 700;
    text-align: center;
  }
  .news-img:hover {
    transform: scale(1.05);
  }
  .img-container {
    height: 250px;
    overflow: hidden;
    position: relative;
  }
  .text-content {
    background-color: #f8f8f8;
    text-align: left;
    padding: 32px 28px 36px;
    height: 234px;
    font-size: 18px;
    .news-title {
      font-size: 1em;
      line-height: 1.625em;
      overflow: hidden;
      margin: 0 0 10px;
      max-height: 116px;
    }
    .news-time {
      height: 46px;
      font-size: 0.875em;
      overflow: hidden;
      margin-bottom: 0;
    }
    .news-content {
      font-size: 0.875em;
      line-height: 1.6875em;
      color: #666;
      clear: both;
    }
  }
  .text-content:hover {
    background-color: #f2f2f2;
  }
  .text-news-list {
    font-size: 18px;
    text-align: left;
    margin: 30px 0;
    display: block;
    .text-title {
      margin: 0 0 10px;
    }
    .text-time {
      font-size: 0.875em;
    }
  }
  .text-news-list:hover {
    .text-title {
      color: #409eff;
    }
    .text-time {
      color: #409eff;
    }
  }
}
.link-logo {
  width: auto;
  max-width: 100%;
  height: 64px;
  cursor: pointer;
}
// ::v-deep .no-border {
//   .el-card__body {
//     padding: 0;
//   }
//   .link-logo {
//     height: 104px;
//   }
// }
</style>
