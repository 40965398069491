/*
 * @Author: chenghaoyuan 517048787@qq.com
 * @Date: 2023-09-11 17:09:21
 * @LastEditors: chenghaoyuan 517048787@qq.com
 * @LastEditTime: 2023-09-14 22:32:46
 * @FilePath: \digital-earth\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    meta: {
      title: "首页",
    },
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: "关于大赛",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/news",
    name: "news",
    meta: {
      title: "新闻公告",
    },
    component: () => import("../views/NewsView.vue"),
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   meta: {
  //     title: "报名",
  //   },
  //   component: () => import("../views/RegisterView.vue"),
  // },
  {
    path: "/personal",
    name: "personal",
    meta: {
      title: "个人中心",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PersonalView.vue"),
  },
  {
    path: "/contest",
    name: "contest",
    meta: {
      title: "大赛赛事",
    },
    component: () => import("../views/ContestView.vue"),
  },
  {
    path: "/process",
    name: "process",
    meta: {
      title: "大赛流程",
    },
    component: () => import("../views/ProcessView.vue"),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    meta: {
      title: "资讯详情",
    },
    component: () => import("../views/NewsDetail.vue"),
  },
  {
    path: "/achievement",
    name: "achievement",
    meta: {
      title: "大赛成绩",
    },
    component: () => import("../views/AchievementView.vue"),
  },
  {
    path: "/study",
    name: "study",
    meta: {
      title: "在线学习",
    },
    component: () => import("../views/StudyView.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // 始终滚动到顶部
    return { x: 0, y: 0 };
  },
});

// 设置导航守卫来动态修改页面标题
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "默认标题";
  console.log(to.path);

  if (to.path === "/personal") {
    let token = localStorage.getItem("token");
    if (!token) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
