<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="400px"
      :append-to-body="true"
      :close-on-click-modal="false"
      class="login"
      :show-close="true"
    >
      <div class="container-left">
        <div class="left-top">
          <div class="top1">首届全国机械行业工业创新大赛</div>
          <div class="top2">构建新质生产力创新改善生态圈</div>
        </div>
        <div class="left-main">
          <div class="main-item">
            <div class="item-title">主办单位：</div>
            <div class="item-desc">中国机械工业企业管理协会</div>
          </div>
          <div class="main-item">
            <div class="item-title">特别支持：</div>
            <div class="item-desc">陕鼓集团</div>
          </div>
          <div class="main-item">
            <div class="item-title">协办单位：</div>
            <div class="item-desc">中国机械工业企业管理协会</div>
          </div>
          <div class="main-item">
            <div class="item-title">承办单位：</div>
            <div class="item-desc">中机企协现代化管理方法应用专委会</div>
          </div>
          <div class="main-item">
            <div class="item-title" style="opacity: 0">承办单位：</div>
            <div class="item-desc">天津昱坤科技有限公司</div>
          </div>
          <div class="main-item">
            <div class="item-title" style="opacity: 0">支持单位：</div>
            <div class="item-desc">中国中车集团</div>
          </div>
          <div class="main-item">
            <div class="item-title">支持单位：</div>
            <div class="item-desc">中国一汽集团</div>
          </div>
          <div class="main-item">
            <div class="item-title" style="opacity: 0">支持单位：</div>
            <div class="item-desc">东方电气集团</div>
          </div>
          <div class="main-item">
            <div class="item-title" style="opacity: 0">支持单位：</div>
            <div class="item-desc">吉利汽车</div>
          </div>
          <div class="main-item">
            <div class="item-title" style="opacity: 0">支持单位：</div>
            <div class="item-desc">潍柴集团</div>
          </div>
          <div class="main-item">
            <div class="item-title" style="opacity: 0">支持单位：</div>
            <div class="item-desc">上海电气集团</div>
          </div>
          <div class="main-item">
            <div class="item-title" style="opacity: 0">支持单位：</div>
            <div class="item-desc">安徽合力股份</div>
          </div>
          <div class="main-item">
            <div class="item-title" style="opacity: 0">支持单位：</div>
            <div class="item-desc">中国兵器集团内蒙古第一机械集团</div>
          </div>
        </div>
      </div>

      <el-card class="login-card">
        <h2 class="login-title">欢迎登录</h2>
        <el-tabs v-model="loginType" class="login-tabs">
          <el-tab-pane label="密码登录" name="pwd">
            <el-form :model="loginForm" ref="loginForm" label-width="80px">
              <el-form-item label="用户名">
                <el-input
                  v-model="loginForm.username"
                  prefix-icon="el-icon-user"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码">
                <el-input
                  v-model="loginForm.password"
                  type="password"
                  prefix-icon="el-icon-lock"
                ></el-input>
              </el-form-item>
              <div style="display: flex; align-items: center">
                <el-button @click="demo">报名注册</el-button>
                <el-button @click="back">返回官网</el-button>
                <!-- <el-checkbox v-model="loginForm.remember">记住我</el-checkbox> -->
                <!-- <a @click="forgotPassword" class="forgot-password">忘记密码?</a> -->
              </div>
              <el-button
                style="width: 100%; margin-top: 20px"
                type="primary"
                @click="login('pwd')"
                >登录</el-button
              >
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="手机号登录" name="phone">
            <div class="phone-login-form">
              <div style="display: flex; margin-bottom: 10px; width: 100%">
                <el-input
                  v-model="phoneLoginForm.phone"
                  class="phone-input"
                  prefix-icon="el-icon-phone"
                ></el-input>
              </div>
              <div style="display: flex">
                <el-input
                  v-model="phoneLoginForm.code"
                  class="code-input"
                  prefix-icon="el-icon-message"
                ></el-input>
                <el-button
                  type="info"
                  @click="sendVerificationCode"
                  :disabled="codeSending"
                  >发送验证码</el-button
                >
              </div>
            </div>

            <el-button
              style="width: 100%; margin-top: 20px"
              type="primary"
              @click="login('phone')"
              >登录</el-button
            >
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </el-dialog>
    <application-dialog ref="applicationDialog"></application-dialog>
  </div>
</template>
<script>
import ApplicationDialog from "./appicationDialog.vue";
import router from "@/router";
import axios from "axios";
export default {
  components: {
    ApplicationDialog,
  },
  data() {
    return {
      dialogVisible: false,
      loginType: "pwd", // 默认选中密码登录
      loginForm: {
        username: "",
        password: "",
        remember: false,
      },
      phoneLoginForm: {
        phone: "",
        code: "",
      },
      codeSending: false, // 是否正在发送验证码
    };
  },
  mounted() {
    this.getTeacherList();
  },
  methods: {
    demo() {
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.$refs.applicationDialog.init();
      });
    },
    back() {
      // 在 Vue 组件或者任何能访问到 Vue Router 实例的地方
      if (this.$route.path === "/home") {
        // 当前路由是 '/home'
        console.log("当前是在主页（/home）");
        this.dialogVisible = false;
      } else {
        // 当前路由不是 '/home'
        console.log("当前不在主页");
        router.push("/home");
      }
    },
    login(type) {
      if (type === "pwd") {
        axios
          .get(
            `http://123.60.129.127:8106/api/gy/company/login/getCode?code=${this.loginForm.password}`
          )
          .then((result) => {
            console.log(result);
            let obj = {
              account: this.loginForm.username,
              password: result.data.msg,
              origin: "password",
              timestamp: 0,
              yfjs_ticket: "",
              grant_type: "password",
            };
            console.log(obj);
            const formData = new FormData();
            for (let key in obj) {
              formData.append(key, obj[key]);
            }
            axios
              .post("http://123.60.129.127:8106/api/oauth/Login", formData)
              .then((res) => {
                console.log(res.data.data.token);
                localStorage.setItem("token", res.data.data.token);
              });
          });

        console.log("用户名：", this.loginForm.username);
        console.log("密码：", this.loginForm.password);
        console.log("记住我：", this.loginForm.remember);
      } else if (type === "phone") {
        // 处理手机号登录逻辑
        console.log("手机号：", this.phoneLoginForm.phone);
        console.log("验证码：", this.phoneLoginForm.code);
      }
      // 可以在这里添加实际的登录逻辑，如向后端发送登录请求等
    },
    sendVerificationCode() {
      // 模拟发送验证码的操作
      this.codeSending = true;
      setTimeout(() => {
        // 模拟发送成功后的操作
        console.log("验证码发送成功");
        this.codeSending = false;
      }, 1500);
    },
    forgotPassword() {
      // 忘记密码的处理逻辑，这里只是简单地弹出一个提示
      alert("忘记密码功能未实现");
    },
    cancel() {
      this.dialogVisible = false;
      // this.$refs.form.resetFields();
    },
    init() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.createForm.resetFields();
        this.loginForm.role = 1;
      });
    },
    getTeacherList() {
      // userApi
      //   .getUserPageList({ pageIndex: 1, pageSize: 1000, role: 2 })
      //   .then((res) => {
      //     this.teacherList = res.response.list;
      //   });
    },
    // beforeAvatarUpload(file) {
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     this.$message.error("上传头像图片大小不能超过 2MB!");
    //   } else {
    //     this.imgUpload(file);
    //   }
    //   return false;
    // },
    // imgUpload(e) {
    //   let file = e;
    //   var reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = () => {
    //     this.imageUrl = reader.result;
    //   };
    //   reader.onerror = function (err) {
    //     console.log(err);
    //   };
    // },
    changeArea() {
      // console.log(this.form.user_area);
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 表单验证通过，执行提交逻辑
          console.log("表单验证通过，提交的数据为:", this.formData);
          this.$message({
            message:
              "您的注册信息已成功提交后台审核，审核通过后账号信息将发送到您的注册邮箱中，请注意查收。感谢您的支持！",
            type: "success",
          });
          // 在这里可以调用后端接口提交数据
        } else {
          console.log("表单验证未通过");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-weight: bold;
  color: #040404;
  font-size: 20px;
}
::v-deep .el-dialog {
  margin: 0;
  margin-left: 110vh !important;
}
.login {
  width: 100%;
  background: url("../assets/images/shoufeng1.jpg") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
// .container-right {
//   position: fixed;
//   right: 5%;
//   top: 100px;
// }
.container-left {
  position: fixed;
  left: 5%;
  top: 50px;
  display: flex;
  flex-direction: column;
  .left-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .top1 {
      font-size: 36px;
      color: #002060;
      font-weight: 700;
    }
    .top2 {
      margin-top: 20px;
      font-size: 32px;
      color: #fff;
      font-weight: 700;
    }
  }
  .left-main {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 100px;
    .main-item + .main-item {
      margin-top: 10px;
    }
    .main-item {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #000;
    }
  }
}
.login-card {
  width: 400px;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
}

.login-tabs {
  margin-bottom: 20px;
}

.el-form-item {
  margin-bottom: 20px;
}

.forgot-password {
  font-size: 14px;
  margin-left: 5px;
  color: #409eff;
  cursor: pointer;
}

.phone-login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.phone-input {
  flex: 1; /* 占据剩余空间 */
  margin-right: 10px; /* 可以根据需要调整间距 */
}

.code-input {
  flex: 1; /* 占据剩余空间 */
  margin-right: 10px; /* 可以根据需要调整间距 */
}
.applicate-bkg {
  width: 100%;
  // background: url("../assets/images/banner1.jpg") no-repeat;
  // background-size: 100% 100%;
  text-align: center;
  div {
    color: #080808;
    font-size: 20px;
    padding-top: 40px;
    font-weight: bold;
  }
}
.avatar-uploader {
  position: absolute;
  top: 177px;
  left: 160px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 6px #a7a7a7;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
::v-deep .el-form {
  padding: 0 50px;
  .el-input__inner {
    // border: 0;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
    height: 32px;
    line-height: 32px;
  }
  .el-input__inner:hover {
    border-color: #1890ff !important;
  }
}
.applicate-button {
  width: 320px;
  border-radius: 20px;
  margin: 10px 50px 30px;
}
</style>
