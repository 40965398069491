/*
 * @Author: chenghaoyuan 517048787@qq.com
 * @Date: 2023-09-11 17:09:21
 * @LastEditors: chenghaoyuan 517048787@qq.com
 * @LastEditTime: 2023-09-14 20:42:49
 * @FilePath: \digital-earth\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/scss/index.scss";
import ApplicationDialog from "../src/views/appicationDialog.vue";
Vue.config.productionTip = false;
Vue.component("ApplicationDialog", ApplicationDialog); // 注册为全局组件
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
