<!--
 * @Author: chenghaoyuan 517048787@qq.com
 * @Date: 2023-09-11 17:09:21
 * @LastEditors: chenghaoyuan 517048787@qq.com
 * @LastEditTime: 2023-09-14 22:26:45
 * @FilePath: \digital-earth\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <div class="top">
      <div class="flex">
        <img src="./assets/images/ISDE.jpg" />
        <div class="title">首届全国机械行业工业工程创新大赛</div>
      </div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        text-color="#000"
        :router="true"
        style="display: flex"
        ref="topMenu"
      >
        <el-menu-item index="home">首页</el-menu-item>
        <el-menu-item index="about">关于大赛</el-menu-item>
        <!-- <el-menu-item index="news">新闻公告</el-menu-item> -->
        <el-menu-item index="contest">赛事详情</el-menu-item>
        <el-menu-item index="process">大赛流程</el-menu-item>
        <!-- <el-menu-item @click="openApploginDialog">登录</el-menu-item> -->
        <el-menu-item index="login">登录</el-menu-item>
        <!-- <el-menu-item index="study">在线学习</el-menu-item> -->
        <el-menu-item @click="openApplicationDialog">大赛报名</el-menu-item>
        <el-menu-item index="personal">个人中心</el-menu-item>
      </el-menu>
    </div>
    <router-view style="margin-top: 70px" />
    <div class="footer">
      <div class="footer-nav">
        <div class="nav-list">
          <div>
            <h3>关于大赛</h3>
            <div>
              <div class="nav" @click="jumpTo('about', 'first')">大赛简介</div>
              <div class="nav" @click="jumpTo('about', 'second')">组织机构</div>
              <div class="nav" @click="jumpTo('about', 'third')">大赛特色</div>
              <!-- <div class="nav" @click="jumpTo('about', 'fourth')">组织机构</div> -->
            </div>
          </div>
          <!-- <div>
            <h3>新闻公告</h3>
            <div>
              <div class="nav">通知公告</div>
              <div class="nav" @click="jumpTo('news', 'first')">新闻动态</div>
              <div class="nav">活动掠影</div>
            </div>
          </div> -->
          <div>
            <h3>大赛赛事</h3>
            <div>
              <div class="nav" @click="jumpTo('contest', 'first')">
                赛道设置
              </div>
              <div class="nav" @click="jumpTo('contest', 'second')">
                参赛要求
              </div>
              <div class="nav" @click="jumpTo('contest', 'third')">
                作品要求
              </div>
            </div>
          </div>
          <div>
            <h3>大赛流程</h3>
            <div>
              <div class="nav" @click="jumpTo('process', 'first')">
                大赛流程
              </div>
              <!-- <div class="nav" @click="jumpTo('process', 'second')">
                赛区设置
              </div> -->
              <div class="nav" @click="jumpTo('process', 'third')">
                奖项设置
              </div>
            </div>
          </div>
          <div>
            <h3>大赛成绩</h3>
            <div>
              <div class="nav" @click="jumpTo('achievement', 'first')">
                查询大赛成绩
              </div>
            </div>
          </div>
          <div>
            <h3>在线学习</h3>
            <div>
              <div class="nav" @click="linkTo('http://119.3.236.157:8892/#/')">
                开始在线学习
              </div>
            </div>
          </div>
          <div>
            <h3>联系我们</h3>
            <div>
              <div class="other-nav">大赛组委会办公室：</div>
              <div class="other-nav">徐老师：13752534413</div>
              <div class="other-nav">大赛组委会邮箱：innomach@163.com</div>
              <!-- <div class="other-nav">innomach@163.com</div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="footer-bq">
        <span>Copyright©All Rights Reserved</span>
        <span
          >“数字地球”全国青少年科技大赛组委会隶属于国际数字地球学会中国国家委员会（中委会）
          版权所有</span
        >
        <span> 京ICP备2021031532号</span>
      </div> -->
    </div>
    <application-dialog ref="applicationDialog"></application-dialog>
    <applogin-dialog ref="apploginDialog"></applogin-dialog>
  </div>
</template>
<script>
import ApplicationDialog from "./views/appicationDialog.vue";
import ApploginDialog from "./views/apploginDialog.vue";
export default {
  data() {
    return {
      // token: localStorage.getItem("token"),
    };
  },
  components: {
    ApplicationDialog,
    ApploginDialog,
  },
  computed: {
    activeIndex() {
      let route = this.$route.path.split("/")[1];
      if (route.includes("news")) {
        route = "news";
      }
      return route;
    },
  },
  methods: {
    jumpTo(router, param) {
      if (this.$route.path.split("/")[1] != router) {
        this.$router.replace({ name: router, params: { activeName: param } });
      }
    },
    linkTo(url) {
      window.open(url);
    },
    openApploginDialog() {
      this.$refs.apploginDialog.init();
    },
    openApplicationDialog() {
      this.$refs.applicationDialog.init();
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
nav {
  padding: 30px;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
  background: #ffffff;
  img {
    height: 60px;
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
  }
}
::v-deep .el-menu-item {
  font-size: 16px;
}
.footer {
  margin-top: 30px;
  background-color: #f2f2f2;
  padding-top: 20px;
  overflow: hidden;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  .footer-nav {
    width: 100%;
    max-width: 1400px;
    padding-left: 15px;
    padding-right: 15px;
    flex-direction: column;
    display: flex;
  }
  .nav-list {
    padding: 24px 0 24px;
    margin-bottom: 38px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    .nav {
      font-size: 0.81225em;
      line-height: 2em;
      color: #595757;
      cursor: pointer;
    }
    .nav:hover {
      color: #409eff;
    }
    .other-nav {
      font-size: 0.81225em;
      line-height: 2em;
      color: #595757;
    }
  }
  .footer-bq {
    width: 100%;
    background-color: #e6e6e6;
    line-height: 30px;
    padding: 10px 0;
    font-size: 0.8em;
    text-align: center;
  }
}
</style>
