<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="620px"
    :append-to-body="true"
    :close-on-click-modal="false"
    class="login"
    :show-close="true"
  >
    <div class="container-left">
      <div class="left-top">
        <div class="top1">首届全国机械行业工业创新大赛</div>
        <div class="top2">构建新质生产力创新改善生态圈</div>
      </div>
      <div class="left-main">
        <div class="main-item">
          <div class="item-title">主办单位：</div>
          <div class="item-desc">中国机械工业企业管理协会</div>
        </div>
        <div class="main-item">
          <div class="item-title">特别支持：</div>
          <div class="item-desc">陕鼓集团</div>
        </div>
        <div class="main-item">
          <div class="item-title">协办单位：</div>
          <div class="item-desc">中国机械工业企业管理协会</div>
        </div>
        <div class="main-item">
          <div class="item-title">承办单位：</div>
          <div class="item-desc">中机企协现代化管理方法应用专委会</div>
        </div>
        <div class="main-item">
          <div class="item-title" style="opacity: 0">承办单位：</div>
          <div class="item-desc">天津昱坤科技有限公司</div>
        </div>
        <div class="main-item">
          <div class="item-title" style="opacity: 0">支持单位：</div>
          <div class="item-desc">中国中车</div>
        </div>
        <div class="main-item">
          <div class="item-title">支持单位：</div>
          <div class="item-desc">中国一汽</div>
        </div>
        <div class="main-item">
          <div class="item-title" style="opacity: 0">支持单位：</div>
          <div class="item-desc">东方电气</div>
        </div>
        <div class="main-item">
          <div class="item-title" style="opacity: 0">支持单位：</div>
          <div class="item-desc">吉利汽车</div>
        </div>
        <div class="main-item">
          <div class="item-title" style="opacity: 0">支持单位：</div>
          <div class="item-desc">潍柴</div>
        </div>
        <div class="main-item">
          <div class="item-title" style="opacity: 0">支持单位：</div>
          <div class="item-desc">上海电气</div>
        </div>
        <div class="main-item">
          <div class="item-title" style="opacity: 0">支持单位：</div>
          <div class="item-desc">安徽合力</div>
        </div>
        <div class="main-item">
          <div class="item-title" style="opacity: 0">支持单位：</div>
          <div class="item-desc">内蒙一机</div>
        </div>
      </div>
    </div>

    <div class="container-right">
      <div class="applicate-bkg">
        <div>大赛报名</div>
      </div>
      <el-form
        ref="form"
        :model="formData"
        :rules="formRules"
        label-width="100px"
        label-position="top"
        style="padding-bottom: 20px"
      >
        <el-form-item label="集团名称" prop="groupName">
          <el-input v-model="formData.groupName"></el-input>
        </el-form-item>
        <el-form-item label="企业类型" prop="companyType">
          <el-select
            style="width: 100%"
            v-model="formData.companyType"
            placeholder="请选择"
          >
            <el-option label="A 中央企业" value="A 中央企业"></el-option>
            <el-option label="B 国有企业" value="B 国有企业"></el-option>
            <el-option label="C 民营企业" value="C 民营企业"></el-option>
            <el-option
              label="D 外商投资/独资企业"
              value="D 外商投资/独资企业"
            ></el-option>
            <el-option
              label="E 港澳台投资企业"
              value="E 港澳台投资企业"
            ></el-option>
            <!-- 其他选项 -->
          </el-select>
        </el-form-item>

        <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="formData.companyName"></el-input>
        </el-form-item>
        <el-form-item label="社会信用统一码" prop="unifiedCode">
          <el-input v-model="formData.unifiedCode"></el-input>
        </el-form-item>
        <!-- <el-form-item label="参赛通道" prop="channel">
          <el-select
            v-model="formData.channel"
            style="width: 100%"
            placeholder="请选择"
          >
            <el-option label="01管理创新赛道" value="channel1"></el-option>
            <el-option label="02改善创新赛道" value="channel2"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="所处行业" prop="industry" class="demo">
          <el-popover
            placement="top-start"
            title="提示"
            width="500"
            trigger="hover"
          >
            <div style="height: 500px; overflow-y: scroll; padding: 10px">
              <h1 style="text-align: center">所属行业分类</h1>
              <h6 style="text-align: center; color: red; margin-top: 10px">
                （依据国家标委发布国民经济行业分类代码 GB/T4754-2017版C项）
              </h6>
              <div v-for="(category, index) in categories" :key="index">
                <h2>
                  {{ category.title }}
                </h2>
                <ul>
                  <li v-for="(item, idx) in category.items" :key="idx">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="img-tips" slot="reference">
              <img
                src="../assets/images/tishi.png"
                style="width: 100%; height: 100%"
                alt=""
              />
            </div>
          </el-popover>
          <el-select
            v-model="formData.industry"
            style="width: 100%"
            placeholder="请选择"
          >
            <el-option
              v-for="item in industryOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
            <!-- 其他选项 -->
          </el-select>
        </el-form-item>
        <el-form-item label="联系人" prop="contactUser">
          <el-input v-model="formData.contactUser"></el-input>
        </el-form-item>
        <el-form-item label="职务" prop="duties">
          <el-input v-model="formData.duties"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="formData.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="formData.email"></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 20px; text-align: center">
          <el-button type="primary" @click="submitForm">注册</el-button>
          <el-button type="primary" @click="loginClick">登录</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
import router from "@/router";
import axios from "axios";
import { regionData } from "element-china-area-data";
// import router from "@/router";
export default {
  name: "ApplicationDialog", // 确保这里的名称和引用的标签名一致
  data() {
    return {
      dialogVisible: false,
      checked: true,
      imageUrl: "",
      regionData,
      categories: [
        {
          title: "01 黑色金属冶炼和压延加工业",
          items: ["钢压延加工、铁合金冶炼"],
        },
        {
          title: "02 有色金属冶炼和压延加工业",
          items: ["有色金属压延加工"],
        },
        {
          title: "03 金属制品业",
          items: [
            "结构性金属制品制造",
            "金属工具制造",
            "集装箱及金属包装容器制造",
            "金属丝绳及其制品制造",
            "建筑&安全用金属制品制造",
            "金属表面处理及热处理加工",
            "搪瓷制品制造",
            "金属制日用品制造",
            "铸造及其他金属制品制造",
          ],
        },
        {
          title: "04 通用设备制造业",
          items: [
            "锅炉及原动设备制造（锅炉及辅助设备制造、内燃机及配件制造、汽轮机及辅机制造、水轮机及辅机制造、风能原动设备制造、其他原动设备制造）",
            "金属加工机械制造（金属切削机床制造、金属成形机床制造、铸造机械制造、金属切割及焊接设备制造、机床功能部件及附件制造、其他金属加工机械制造）",
            "物料搬运设备制造（轻小型起重设备制造、生产专用起重机制造、生产专用车辆制造、连续搬运设备制造、电梯、自动扶梯及升降机制造、客运索道制造、机械式停车设备制造）",
            "泵、阀门、压缩机及类似机械制造(泵及真空设备制造、气体压缩机械制造、阀门和旋塞制造、液压动力机械及元件制造、液力动力机械元件制造、气压动力机械及元件制造）",
            "轴承、齿轮和传动部件制造（滚动轴承制造、滑动轴承制造、齿轮及齿轮减、变速箱制造、其他传动部件制造）",
            "烘炉、风机、包装等设备制造（烘炉&熔炉及电炉制造、风机&风扇制造、气体&液体分离及纯净设备制造、制冷&空调设备制造、风动和电动工具制造、喷枪及类似器具制造、包装专用设备制造）",
            "文化、办公用机械制造（电影机械制造、幻灯及投影设备制造、照相机及器材制造、复印和胶印设备制造、计算器及货币专用设备制造、其他文化和办公用机械制造）",
            "通用零部件制造（金属密封件制造、紧固件制造、弹簧制造、机械零部件加工、其他通用零部件制造）",
            "其他通用设备制造业（工业机器人制造、特殊作业机器人制造、增材制造装备制造、其他未列明通用设备制造业）",
          ],
        },
        {
          title: "05 专用设备制造业",
          items: [
            "采矿、冶金、建筑专用设备制造（矿山机械制造、石油钻采专用设备制造、深海石油钻探设备制造、建筑工程用机械制造、建筑材料生产专用机械制造、冶金专用设备制造、隧道施工专用机械制造）",
            "化工、木材、非金属加工专用设备制造（炼油&化工生产专用设备制造、橡胶加工专用设备制造、塑料加工专用设备制造、木竹材加工机械制造、模具制造、其他非金属加工专用设备制造）",
            "食品、饮料、烟草及饲料生产专用设备制造（食品&酒&饮料及茶生产专用设备制造、农副食品加工专用设备制造、烟草生产专用设备制造、饲料生产专用设备制造）",
            "印刷、制药、日化及日用品生产专用设备制造（制浆和造纸专用设备制造、印刷专用设备制造、日用化工专用设备制造、制药专用设备制造、照明器具生产专用设备制造、玻璃&陶瓷和搪瓷制品生产)、专用设备制造、其他日用品生产专用设备制造）",
            "纺织、服装和皮革加工专用设备制造（纺织专用设备制造、皮革&毛皮及其制品加工专用设备制造、缝制机械制造、洗涤机械制造）",
            "电子和电工机械专用设备制造（电工机械专用设备制造、半导体器件专用设备制造、电子元器件与机电组件设备制造、其他电子专用设备制造）",
            "农、林、牧、渔专用机械制造（拖拉机制造、机械化农业及园艺机具制造、营林及木竹采伐机械制造、畜牧机械制造、渔业机械制造、农林牧渔机械配件制造、棉花加工机械制造、其他农&林&牧&渔业机械制造）",
            "医疗仪器设备及器械制造（医疗诊断&监护及治疗设备制造、口腔科用设备及器具制造、医疗实验室及医用消毒设备和器具制造、医疗&外科及兽医用器械制造、机械治疗及病房护理设备制造、康复辅具制造、眼镜制造、其他医疗设备及器械制造）",
            "环保、邮政、社会公共服务及其他专用设备制造（环境保护专用设备制造、地质勘查专用设备制造、邮政专用机械及器材制造、商业&饮食&服务专用设备制造、社会公共安全设备及器材制造、交通安全&管制及类似专用设备制造、水资源专用机械制造）",
            "其他专用设备制造",
          ],
        },
        {
          title: "06 汽车制造业",
          items: [
            "汽车整车制造（汽柴油车整车制造、新能源车整车制造）",
            "汽车用发动机制造",
            "改装汽车制造",
            "低速汽车制造",
            "电车制造",
            "汽车车身及挂车制造",
            "汽车零部件及配件制造",
          ],
        },
        {
          title: "07 铁路、船舶、航空航天和其他运输设备制造业",
          items: [
            "铁路运输设备制造（高铁车组制造、铁路机车车辆制造、窄轨机车车辆制造、高铁设备&配件制造、铁路机车车辆配件制造、铁路专用设备及器材和配件制造、其他铁路运输设备制造）",
            "城市轨道交通设备制造",
            "船舶及相关装置制造（金属船舶制造、非金属船舶制造、娱乐船和运动船制造、船用配套设备制造、船舶改装、船舶拆除、海洋工程装备制造、航标器材及其他相关装置制造）",
            "航空、航天器及设备制造（飞机制造、航天器及运载火箭制造、航天相关设备制造、航空相关设备制造、其他航空航天器制造）",
            "摩托车制造（摩托车整车制造、摩托车零部件及配件制造）",
            "自行车和残疾人座车制造（自行车制造、残疾人座车制造）",
            "助动车制造、非公路休闲车及零配件制造",
            "潜水救捞及其他未列明运输设备制造（潜水装备制造、水下救捞装备制造、其他未列明运输设备制造）",
          ],
        },
        {
          title: "08 电气机械和器材制造业",
          items: [
            "电机制造（发电机及发电机组制造、电动机制造、微特电机及组件制造、其他电机制造）",
            "输配电及控制设备制造（变压器&整流器和电感器制造、电容器及其配套设备制造、配电开关控制设备制造、电力电子元器件制造、光伏设备及元器件制造、其他输配电及控制设备制造）",
            "电线、电缆、光缆及电工器材制造（电线和电缆制造、光纤制造、光缆制造、绝缘制品制造、其他电工器材制造）",
            "电池制造（锂离子电池制造、镍氢电池制造、铅蓄电池制造、锌锰电池制造、其他电池制造）",
            "家用电力器具制造（家用制冷电器具制造、家用空气调节器制造、家用通风电器具制造、家用厨房电器具制造、家用清洁卫生电器具制造、家用美容和保健护理电器具制造、家用电力器具专用配件制造、其他家用电力器具制造）",
            "非电力家用器具制造（燃气及类似能源家用器具制造、太阳能器具制造、其他非电力家用器具制造）",
            "照明器具制造（电光源制造、照明灯具制造、舞台及场地用灯制造、智能照明器具制造、灯用电器附件及其他照明器具制造）",
            "其他电气机械及器材制造（电气信号设备装置制造）",
            "其他未列明电气机械及器材制造",
          ],
        },

        {
          title: "09 计算机、通信和其他电子设备制造业",
          items: [
            "计算机制造（计算机整机制造、计算机零部件制造、计算机外围设备制造、工业控制计算机及系统制造、信息安全设备制造、其他计算机制造）",
            "通信设备制造（通信系统设备制造、通信终端设备制造）",
            "广播电视设备制造（广播电视节目制作及发射设备制造、广播电视接收设备制造、广播电视专用配件制造、专业音响设备制造、应用电视设备及其他广播电视设备制造）",
            "雷达及配套设备制造",
            "非专业视听设备制造（电视机制造、音响设备制造、影视录放设备制造）",
            "智能消费设备制造（可穿戴智能设备制造、智能车载设备制造、智能无人飞行器制造、服务消费机器人制造）",
            "其他智能消费设备制造",
            "电子器件制造（电子真空器件制造、半导体分立器件制造、集成电路制造、显示器件制造、半导体照明器件制造、光电子器件制造、其他电子器件制造）",
            "电子元件及电子专用材料制造（电阻电容电感元件制造、电子电路制造、敏感元件及传感器制造、电声器件及零件制造、电子专用材料制造、其他电子元件制造）",
            "其他电子设备制造",
          ],
        },
        {
          title: "10 仪器仪表制造业",
          items: [
            "通用仪器仪表制造（工业自动控制系统装置制造、电工仪器仪表制造、绘图&计算及测量仪器制造、实验分析仪器制造、试验机制造、供应用仪器仪表制造、其他通用仪器制造）",
            "专用仪器仪表制造（环境监测专用仪器仪表制造、运输设备及生产用计数仪表制造、导航&测绘&气象及海洋专用仪器制造、农林牧渔专用仪器仪表制造、地质勘探和地震专用仪器制造、教学专用仪器制造、核子及核辐射测量仪器制造、电子测量仪器制造、其他专用仪器制造）",
            "钟表与计时仪器制造",
            "光学仪器制造",
            "衡器制造",
            "其他仪器仪表制造业",
          ],
        },
        {
          title: "11 其他制造业",
          items: ["日用杂品制造", "核辐射加工"],
        },
        {
          title: "12 废弃资源综合利用业",
          items: ["金属废料和碎屑加工处理", "非金属废料和碎屑加工处理"],
        },
        {
          title: "13 金属制品、机械和设备修理业",
          items: [
            "金属制品修理",
            "通用设备修理",
            "专用设备修理",
            "铁路&船舶&航空航天等运输设备修理",
            "电气设备修理",
            "仪器仪表修理",
            "其他机械和设备修理业",
          ],
        },
      ],
      industryOption: [
        {
          value: "01 黑色金属冶炼和压延加工业",
          label: "01 黑色金属冶炼和压延加工业",
        },
        {
          value: "02 有色金属冶炼和压延加工业",
          label: "02 有色金属冶炼和压延加工业",
        },
        { value: "03 金属制品业", label: "03 金属制品业" },
        { value: "04 通用设备制造业", label: "04 通用设备制造业" },
        { value: "05 专用设备制造业", label: "05 专用设备制造业" },
        { value: "06 汽车制造业", label: "06 汽车制造业" },
        {
          value: "07 铁路、船舶、航空航天和其他运输设备制造",
          label: "07 铁路、船舶、航空航天和其他运输设备制造",
        },
        { value: "08 电气机械和器材制造业", label: "08 电气机械和器材制造业" },
        {
          value: "09 计算机、通信和其他电子设备制造业",
          label: "09 计算机、通信和其他电子设备制造业",
        },
        { value: "10 仪器仪表制造业", label: "10 仪器仪表制造业" },
        { value: "11 其他制造业", label: "11 其他制造业" },
        { value: "12 废弃资源综合利用业", label: "12 废弃资源综合利用业" },
        {
          value: "13 金属制品、机械和设备修理业",
          label: "13 金属制品、机械和设备修理业",
        },
      ],

      formData: {
        groupName: "",
        companyType: "",
        companyName: "",
        unifiedCode: "",
        industry: "",
        contactUser: "",
        duties: "",
        phone: "",
        email: "",
      },
      formRules: {
        groupName: [
          { required: true, message: "请输入集团名称", trigger: "blur" },
        ],
        companyType: [
          { required: true, message: "请选择企业类型", trigger: "change" },
        ],
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        unifiedCode: [
          { required: true, message: "请输入社会信用统一码", trigger: "blur" },
          {
            pattern:
              /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/,
            message: "请输入社会信用统一码格式不正确",
            trigger: "blur",
          },
        ],
        industry: [
          { required: true, message: "请选择所属行业", trigger: "change" },
        ],
        contactUser: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        duties: [{ required: true, message: "请输入职务", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            type: "email",
            message: "邮箱格式不正确",
            trigger: ["blur", "change"],
          },
        ],
      },
      teacherList: [],
    };
  },
  mounted() {},
  methods: {
    loginClick() {
      if (this.$route.path == "/login") {
        this.cancel();
      } else {
        this.cancel();
        router.push("/login");
      }
    },
    cancel() {
      this.dialogVisible = false;
      // this.$emit("close"); // 发射关闭事件，通知父组件
      this.$refs.form.resetFields();
    },
    init() {
      this.dialogVisible = true;
    },

    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 表单验证通过，执行提交逻辑
          console.log("表单验证通过，提交的数据为:", this.formData);
          axios
            .post("http://123.60.129.127:8106/api/gy/company/", this.formData)
            .then(({ data }) => {
              console.log(data);
              if (data.code == "400") {
                this.$message({
                  message: data.msg,
                  type: "error",
                  center: true,
                });
              } else if (data.code == "200") {
                this.$alert(
                  "您的注册信息已成功提交后台审核，审核通过后账号信息将发送到您的注册邮箱中，请注意查收。感谢您对大赛的支持！",
                  "提示",
                  {
                    confirmButtonText: "确定",
                    callback: () => {
                      this.$message({
                        type: "success",
                        message: "信息提交成功",
                      });
                    },
                  }
                );
                this.cancel();
              }
            });

          // 在这里可以调用后端接口提交数据
        } else {
          console.log("表单验证未通过");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-weight: bold;
  color: #040404;
  font-size: 20px;
}
::v-deep .el-dialog {
  margin: 0;
  margin-left: 110vh !important;
}

.el-form-item__label {
  padding: 0;
}

.el-form-item {
  margin-bottom: 0;
}

.demo {
  position: relative;
}

.img-tips {
  position: absolute;
  top: -40px;
  left: 75px;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
}
.login {
  width: 100%;
  background: url("../assets/images/shoufeng1.jpg") no-repeat;
  background-size: 100% 100%;
  // text-align: center;
}
// .container-right {
//   position: fixed;
//   right: 5%;
//   top: 100px;
// }
.container-left {
  position: fixed;
  left: 5%;
  top: 50px;
  display: flex;
  flex-direction: column;
  .left-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .top1 {
      font-size: 36px;
      color: #002060;
      font-weight: 700;
    }
    .top2 {
      margin-top: 20px;
      font-size: 32px;
      color: #fff;
      font-weight: 700;
    }
  }
  .left-main {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-left: 100px;
    .main-item + .main-item {
      margin-top: 10px;
    }
    .main-item {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #000;
    }
  }
}
.applicate-bkg {
  width: 100%;
  // background: url("../assets/images/banner1.jpg") no-repeat;
  // background-size: 100% 100%;
  text-align: center;
  div {
    color: #080808;
    font-size: 20px;
    padding-top: 40px;
    font-weight: bold;
  }
}
.avatar-uploader {
  position: absolute;
  top: 177px;
  left: 160px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 6px #a7a7a7;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
::v-deep .el-form {
  margin-top: 70px;
  padding: 0 50px;
  .el-input__inner {
    // border: 0;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
    height: 32px;
    line-height: 32px;
  }
  .el-input__inner:hover {
    border-color: #1890ff !important;
  }
}
.applicate-button {
  width: 320px;
  border-radius: 20px;
  margin: 10px 50px 30px;
}
</style>
